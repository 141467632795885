import { AutoAwesome, Event } from '@mui/icons-material';
import { CampaignChannels } from 'domains/campaigns/types';
import FormLayout from 'domains/core/components/FormLayout';
import { CampaignStateTypes } from '../../../customCampaignReducer';
import DetailItem from 'domains/core/components/DetailItem';
import { formatDateTime } from 'domains/core/components/DateRangeText/DateRangeText';
import { Stack, Tooltip, Typography } from '@mui/material';
import { getIsRecurrenceEnabled, getRecurrenceLabel } from 'domains/campaigns/utils';
import { CustomCampaignSteps } from 'domains/campaigns/components/CustomCampaign/CustomCampaign';

type Props = {
    campaignState: CampaignStateTypes;
    setActiveStep: (step: CustomCampaignSteps) => void;
};

const CustomCampaignPublishStep = ({ campaignState, setActiveStep }: Props) => {
    const isDirectMailSend = campaignState.sends[0].channel === CampaignChannels.DIRECT_MAIL;

    const guidingText = `Take this moment to confirm the details of your Curation. ${
        isDirectMailSend
            ? 'Once the Curation is published, the files will start printing the business day after the schedule date. '
            : ''
    }While you can always cancel upcoming sends, please note that you cannot take back any that have already been sent.`;

    const tooltipMessage = 'Click to edit';

    const reEntryDimension = campaignState.reentryDetails?.dimension === 'hour' ? 'hours' : 'days';
    const scheduledTimestamp = campaignState.sends[0].scheduledTimestamp;
    const formattedScheduledTimestamp = formatDateTime(scheduledTimestamp);
    const recurrenceFrequency =
        campaignState.sends[0].channel === CampaignChannels.EMAIL ? campaignState.sends[0].recurrenceFrequency : null;
    const isRecurringCampaign = getIsRecurrenceEnabled(recurrenceFrequency);
    const recurrenceLabel = getRecurrenceLabel(recurrenceFrequency, scheduledTimestamp);
    const recurrenceLabelForDisplay = recurrenceLabel.charAt(0).toUpperCase() + recurrenceLabel.slice(1);

    return (
        <FormLayout header="last step!" stepName="publish" icon={<AutoAwesome />} guidingText={guidingText}>
            <>
                <DetailItem
                    header="Scheduled"
                    description={
                        <Stack>
                            <Typography variant="body1">{formattedScheduledTimestamp}</Typography>
                            {isRecurringCampaign && (
                                <Typography variant="body2" sx={{ marginBottom: '16px' }}>
                                    {recurrenceLabelForDisplay}
                                </Typography>
                            )}
                        </Stack>
                    }
                    descriptionIcon={
                        <Tooltip title={tooltipMessage}>
                            <Event onClick={() => setActiveStep(CustomCampaignSteps.SCHEDULE_CURATION)} />
                        </Tooltip>
                    }
                />
                {campaignState?.reentryDetails && (
                    <DetailItem
                        header="Contacts Re-Enter"
                        description={`${campaignState.reentryDetails.value} ${reEntryDimension} after last send`}
                    />
                )}
            </>
        </FormLayout>
    );
};

export default CustomCampaignPublishStep;
