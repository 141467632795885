import {
    DoNotDisturb,
    FolderOpenOutlined,
    MailOutline,
    MarkunreadMailboxOutlined,
    SettingsPhoneOutlined,
    SupervisedUserCircleOutlined,
    TextsmsOutlined,
    Wysiwyg,
} from '@mui/icons-material';
import { SvgIconProps } from '@mui/material';
import { CampaignChannels } from 'domains/campaigns/types';
import { ContentTypeForComparison } from 'domains/content/types';
import { SegmentTableItemType } from 'domains/segments/types';

export const channelIcons: Record<string, React.ComponentType<SvgIconProps>> = {
    [CampaignChannels.NOOP]: DoNotDisturb,
    [CampaignChannels.DIRECT_MAIL]: MarkunreadMailboxOutlined,
    [CampaignChannels.SMS]: TextsmsOutlined,
    [CampaignChannels.VOICE_CALL]: SettingsPhoneOutlined,
    [CampaignChannels.EMAIL || ContentTypeForComparison.EMAIL]: MailOutline,
    [ContentTypeForComparison.LANDING_PAGE]: Wysiwyg,
    [ContentTypeForComparison.FOLDER || SegmentTableItemType.FOLDER]: FolderOpenOutlined,
    [SegmentTableItemType.AUDIENCE]: SupervisedUserCircleOutlined,
    [CampaignChannels.VOICE_CALL]: SettingsPhoneOutlined,
};
