import { useEffect } from 'react';
import { CustomCampaignSteps } from 'domains/campaigns/components/CustomCampaign/CustomCampaign';
import { CampaignStateTypes } from 'domains/campaigns/components/CustomCampaign/customCampaignReducer';
import { channelIcons } from 'domains/campaigns/pages/CurationsPage/utils';
import { SmsSendInput } from 'domains/campaigns/types';
import FormLayout from 'domains/core/components/FormLayout';
import ContentWrapper from './ContentWrapper';

type Props = {
    activeSendIndex: number;
    campaignState: CampaignStateTypes;
    dispatchCampaignAction: React.Dispatch<any>;
    setIsContinueEnabled: (isContinueEnabled: boolean) => void;
    setNextStep: (step: CustomCampaignSteps) => void;
};

const SMSContentStep = ({
    activeSendIndex,
    campaignState,
    dispatchCampaignAction,
    setIsContinueEnabled,
    setNextStep,
}: Props) => {
    const currentSend = campaignState.sends[activeSendIndex];
    const { content } = currentSend as SmsSendInput;

    useEffect(() => {
        // SMS curations don't support delayed sends yet, but we're leaving this here for when it does
        if (activeSendIndex > 0) {
            setNextStep(CustomCampaignSteps.CHOOSE_NEXT_STEP);
        } else {
            setNextStep(CustomCampaignSteps.SCHEDULE_CURATION);
        }
        // The dependency array is empty because this useEffect should run exactly once after the initial rendering of the component.
        // The hook is not dependent on any props or state variables to re-run. This is intended to perform the initial setup based on
        // the `activeSendIndex` value when the component mounts without reacting to any further updates or re-renders.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isContentMinLength = content?.length >= 3;

    useEffect(() => {
        setIsContinueEnabled(isContentMinLength);
    }, [content, isContentMinLength, setIsContinueEnabled]);

    const StepIcon = channelIcons.SMS;

    return (
        <FormLayout
            header="create content"
            icon={<StepIcon />}
            guidingText="Write the content you want your audience to receive. Keep the goal of your Curation in mind and choose content that best supports your objective."
            stepName="SMS"
        >
            <ContentWrapper
                activeSendIndex={activeSendIndex}
                campaignState={campaignState}
                dispatchCampaignAction={dispatchCampaignAction}
                isContentMinLength={isContentMinLength}
            />
        </FormLayout>
    );
};

export default SMSContentStep;
