import { Skeleton, Stack } from '@mui/material';
import mime from 'mime';
import { useRef, useState } from 'react';
import Button from 'domains/core/components/Button';
import DeleteButton from 'domains/core/components/DeleteButton';
import { resetContent, setDirectMailContent } from 'domains/campaigns/components/CustomCampaign/customCampaignActions';
import useUploadPdf from 'hooks/mutations/useUploadPdf';
import { AllowedMimeTypes } from 'models/enums';
import AlertMessage from '../AlertMessage';

type Props = {
    activeSendIndex: number;
    dispatchCampaignAction: any;
    fileName: string;
};

const FileUploader = ({ activeSendIndex, dispatchCampaignAction, fileName }: Props) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [alertText, setAlertText] = useState('');
    const [isError, setIsError] = useState(false);

    const handleUploadButtonClick = () => fileInputRef.current?.click();

    const { mutate: uploadPdf, isLoading: isUploadPdfLoading } = useUploadPdf({
        onSuccess: ({ contentId }, formData) => {
            const fileObject = formData.get('fileObject');

            if (fileObject instanceof File) {
                dispatchCampaignAction(
                    setDirectMailContent({ contentId, contentName: fileObject.name }, activeSendIndex)
                );
                setAlertText('File attached.');
            }
        },
        onError: (error) => {
            setIsError(true);
            setAlertText(`File was not attached. ${error.message}`);
        },
    });

    const onFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (!file) {
            return;
        }

        const mimeType = mime.getType(file.name);
        const isAllowedMimeType = mimeType === AllowedMimeTypes.APPLICATION_PDF;

        if (!isAllowedMimeType) {
            dispatchCampaignAction(resetContent(activeSendIndex));
            setAlertText('Invalid file format. Please choose a valid file format.');
            setIsError(true);
            return;
        }

        const formData = new FormData();
        formData.append('fileObject', file);

        uploadPdf(formData);
        setAlertText('');
        setIsError(false);
    };

    const onRemoveUploadButtonClick = () => {
        dispatchCampaignAction(resetContent(activeSendIndex));
        setAlertText('');
        setIsError(false);
    };

    const uploadButtonText = isUploadPdfLoading ? <Skeleton width="100%" /> : fileName || 'Upload From Computer';

    return (
        <Stack rowGap={2}>
            <Stack direction="row" alignItems="center" columnGap={1}>
                <input
                    accept="application/pdf"
                    onChange={onFileInputChange}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    type="file"
                />
                <Button
                    disabled={isUploadPdfLoading}
                    fullWidth
                    onClick={handleUploadButtonClick}
                    sx={{
                        justifyContent: 'flex-start',
                        textTransform: 'none',
                    }}
                    variant="outlined"
                >
                    {uploadButtonText}
                </Button>
                {fileName && !isUploadPdfLoading && <DeleteButton onClick={onRemoveUploadButtonClick} />}
            </Stack>
            <AlertMessage isError={isError} text={alertText} />
        </Stack>
    );
};

export default FileUploader;
