import { Download } from '@mui/icons-material';
import { Box, Stack, SxProps, Theme, Typography } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import IconButton from 'domains/core/components/IconButton';
import { cardStyles } from './Card';

const headerStyles: SxProps<Theme> = {
    paddingLeft: '24px',
    textAlign: 'auto',
};

type Props = {
    description?: string;
    handleDownloadClick?: () => void;
    subtitle: string;
    title: string;
};

const DashboardHeader = ({ description, handleDownloadClick, subtitle, title }: Props) => {
    const { insightsDownloadNativeDashboards: isDownloadFeatureEnabled } = useFlags();

    return (
        <Box sx={{ ...cardStyles, ...headerStyles }}>
            <Stack flexDirection="row" height="100%" justifyContent="space-between">
                <Stack justifyContent="center">
                    <Typography variant="caption" color="action.active">
                        {title}
                    </Typography>
                    <Typography variant="h3" mt={1} mb={1}>
                        {subtitle}
                    </Typography>
                    {description}
                </Stack>
                {isDownloadFeatureEnabled && handleDownloadClick && (
                    <span style={{ padding: '8px' }}>
                        <IconButton handleClick={handleDownloadClick} Icon={Download} title="Download" />
                    </span>
                )}
            </Stack>
        </Box>
    );
};

export default DashboardHeader;
