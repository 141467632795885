import { Grid } from '@mui/material';
import { useState } from 'react';
import OverviewStatsCards from './cards/OverviewStatsCards';
import RatesCards from './cards/RatesCards';
import TopSendsByClickCard from './cards/TopSendsByClickCard';
import TopSendsByOpenCard from './cards/TopSendsByOpenCard';
import DashboardHeader from '../DashboardHeader';
import { oneYearAgo, today } from '../filtering/DateFilter/DateFilter';
import { FiltersForApiState, FilterValuesState } from '../filtering/types';
import { getTimeframeForApi } from '../filtering/utils';
import Filters from './Filters';

const ExecutiveOverviewDashboard = () => {
    const end = today;
    const start = oneYearAgo;
    // This state value is tracking the input values of the fields used for filtering.
    const [filterValues, setFilterValues] = useState<FilterValuesState>({
        includesUnsubscribes: false,
        filterTimeframe: { start, end },
        filterRelativeTimeframe: {
            direction: null,
            duration: null,
            unit: null,
        },
    });
    const [isRelativeTimeframe, setIsRelativeTimeframe] = useState(false);

    const timeframeForApi = getTimeframeForApi(isRelativeTimeframe, filterValues);

    // This state value is tracking what's sent to the API for filtering, which may not
    // match the field values as filters are not applied until a button is clicked.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [filtersForApi, setFiltersForApi] = useState<FiltersForApiState>({
        unsubs: filterValues.includesUnsubscribes,
        timeframe: timeframeForApi,
    });

    // This handles clicking on the button to apply filters by setting the filtersForApi
    // state to match the input state values.
    const handleApplyFilters = () => {
        setFiltersForApi({
            unsubs: filterValues.includesUnsubscribes,
            timeframe: timeframeForApi,
        });
    };

    // This function will handle the API call to get the downloadable report according to the
    // filters applied in the current state.
    const handleDownloadClick = () => {};

    return (
        <div style={{ paddingBottom: '32px' }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Filters
                        filterValues={filterValues}
                        handleApplyFilters={handleApplyFilters}
                        isRelativeTimeframe={isRelativeTimeframe}
                        setFilterValues={setFilterValues}
                        setIsRelativeTimeframe={setIsRelativeTimeframe}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DashboardHeader
                        title="executive overview"
                        subtitle="see what's working"
                        description="Share a high level overview of your engagement metrics with leaders on your team."
                        handleDownloadClick={handleDownloadClick}
                    />
                </Grid>
                <OverviewStatsCards filtersForApi={filtersForApi} />
                <RatesCards filtersForApi={filtersForApi} />
                <Grid item xs={12} md={6}>
                    <TopSendsByOpenCard filtersForApi={filtersForApi} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TopSendsByClickCard filtersForApi={filtersForApi} />
                </Grid>
            </Grid>
        </div>
    );
};

export default ExecutiveOverviewDashboard;
