import { useHistory } from 'react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useContentQueryManager from 'hooks/useContentQueryParams';
import { getContentCreatePath } from './ContentTable/utils';
import { URLPaths } from 'models/enums';

const useGetCreateContentDropdownOptions = () => {
    const history = useHistory();
    const { folderId: parentFolderId } = useContentQueryManager();
    const { directMailEditor: hasDirectMailEditorFeature } = useFlags();

    return [
        {
            label: 'email',
            onClick: () => {
                const path = getContentCreatePath({ isEmail: true, parentFolderId });
                history.push(path);
            },
        },
        {
            dataTestId: 'create-landing-page',
            label: 'landing page',
            onClick: () => {
                const path = getContentCreatePath({ isEmail: false, parentFolderId });
                history.push(path);
            },
        },
        ...(hasDirectMailEditorFeature
            ? [
                  {
                      label: 'direct mail',
                      onClick: () => {
                          //TODO: Update getContentCreatePath() and subsequent components to conditionally handle the path based on contentType than boolean check isEmail
                          history.push(URLPaths.CONTENT_CREATE_DIRECT_MAIL);
                      },
                  },
              ]
            : []),
    ];
};

export default useGetCreateContentDropdownOptions;
