import { useEffect, useState } from 'react';
import { CustomCampaignSteps } from 'domains/campaigns/components/CustomCampaign/CustomCampaign';
import { addConditionalSplitSends } from 'domains/campaigns/components/CustomCampaign/customCampaignActions';
import { CampaignStateTypes } from 'domains/campaigns/components/CustomCampaign/customCampaignReducer';
import { getSteps, MAX_SENDS_AMOUNT, renderChooseStepsForm } from '../utils';

type Props = {
    activeSendIndex: number;
    campaignState: CampaignStateTypes;
    dispatchCampaignAction: React.Dispatch<any>;
    setActiveStep: (step: CustomCampaignSteps) => void;
};

const CustomCampaignChooseSendTypeStep = ({
    activeSendIndex,
    campaignState,
    dispatchCampaignAction,
    setActiveStep,
}: Props) => {
    const [selectedStep, setSelectedStep] = useState(null);

    const getIsStepDisabled = (step: CustomCampaignSteps) => {
        switch (step) {
            case CustomCampaignSteps.SELECT_CONTENT:
                return false;
            case CustomCampaignSteps.SET_TIME_DELAY:
                return true;
            case CustomCampaignSteps.CONDITIONAL_SPLIT:
                return false;
            case CustomCampaignSteps.SCHEDULE_CURATION:
                return true;
            default:
                return false;
        }
    };

    useEffect(() => {
        const isSelectedStepDisabled = getIsStepDisabled(selectedStep);
        if (isSelectedStepDisabled) return;

        if (selectedStep) setActiveStep(selectedStep);

        // We only want this to run when the send index or selected step change.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSendIndex, selectedStep]);

    // Handles adding/configuring sends based on next step selection
    useEffect(() => {
        // Configure send added after time delay as conditional split
        // and add "does not meet criteria" send
        if (selectedStep === CustomCampaignSteps.CONDITIONAL_SPLIT) {
            dispatchCampaignAction(addConditionalSplitSends(activeSendIndex));
        }

        // We only want this to run when the send index or selected step change.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSendIndex, selectedStep]);

    const handleStepClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedStep((event.target as HTMLInputElement).value);
    };

    const maxSendsReached = campaignState.sends.length === MAX_SENDS_AMOUNT;
    const steps = getSteps(getIsStepDisabled, maxSendsReached);

    return renderChooseStepsForm(handleStepClick, selectedStep, steps);
};

export default CustomCampaignChooseSendTypeStep;
