import { useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import Button from 'domains/core/components/Button';
import { themeColors } from 'colors';

const boxContainerStyles = {
    flexGrow: '1',
    width: '100%',
    height: '100%',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: 2,
};
const imageStyles = {
    width: 'calc(100% - 2rem)',
    height: '12rem',
    position: 'absolute',
    padding: '1rem',
};
const typeContainerStyles = {
    flexGrow: '1',
    width: '100%',
    height: '100%',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'flex-end',
};
const backgroundStyles = {
    zIndex: '1',
    bottom: '0rem',
    left: '0rem',
    display: 'flex',
    width: 'calc(100% - 2.25rem)',
    background: 'rgba(255, 255, 255, 0.95)',
    padding: '1.125rem 1.375rem',
    justifyContent: 'flex-end',
};

const renderButton = ({ isHovered, label, onClick }: { isHovered: boolean; label: string; onClick: () => void }) => {
    const buttonStyles = {
        background: themeColors.action.active,
        position: 'relative',
        bottom: '0.5rem',
        zIndex: isHovered ? '100' : '-100',
        '&:hover': {
            background: themeColors.action.selected,
        },
        width: '160px',
    };

    return (
        <Button
            color="secondary"
            key={label} // For list rendering
            onClick={onClick}
            size="medium"
            sx={buttonStyles}
            variant="contained"
        >
            {label}
        </Button>
    );
};

export type CurationItemProps = {
    imageSrc: any;
    onClick?: () => void;
    options?: { label: string; onClick: () => void }[];
    subtitle?: string;
    title: string;
    updateTag?: React.ReactNode;
};

const CurationItem = ({ imageSrc, onClick, options = [], subtitle, title, updateTag }: CurationItemProps) => {
    const [isHovered, setIsHovered] = useState(false);

    const containerStyles = {
        position: 'relative',
        borderRadius: '6px',
        height: '20rem',
        overflow: 'hidden',
        alignItems: 'flex-end',
        border: '0.125rem solid',
        borderColor: 'other.black',
        '&:hover': {
            cursor: options?.length ? 'auto' : 'pointer',
        },
    };

    const overlayHoverStyles = {
        zIndex: '10',
        flexGrow: '1',
        width: '100%',
        height: '100%',
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: isHovered ? '#FFFFFFAA' : '#FFFFFF00',
    };

    const typeStyles = {
        color: isHovered ? 'action.active' : 'primary.main',
        fontSize: '1.5rem',
        paddingTop: '0rem',
        fontWeight: '500',
        lineHeight: '2rem',
    };

    const buttonOptions = options.length
        ? options.map(({ label, onClick }) => renderButton({ isHovered, label, onClick }))
        : renderButton({ isHovered, label: 'Start Building', onClick });

    return (
        <Stack
            sx={containerStyles}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={onClick}
        >
            {updateTag}
            <Box sx={overlayHoverStyles} />
            <Box sx={imageStyles}>{imageSrc}</Box>
            <Stack sx={boxContainerStyles}>{buttonOptions}</Stack>
            <Stack sx={typeContainerStyles}>
                <Stack sx={backgroundStyles}>
                    <Typography sx={typeStyles}>{title}</Typography>
                    <Typography variant="body2">{subtitle}</Typography>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default CurationItem;
