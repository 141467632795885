import { Grid } from '@mui/material';
import AgeCard from './cards/AgeCard';
import SexCard from './cards/SexCard';
import LocationCard from './cards/LocationCard';
import DomainsCard from './cards/DomainsCard';
import UnsubscribesCard from './cards/UnsubscribesCard';
import SubscriptionStatusCard from './cards/SubscriptionStatusCard';
import SubscribersOverTimeCard from './cards/SubscribersOverTimeCard';
import ContactsCard from './cards/ContactsCard';
import DashboardHeader from '../DashboardHeader';

const ContactsDashboard = () => {
    const handleDownloadClick = () => {};

    return (
        <div style={{ paddingBottom: '32px' }}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                    <DashboardHeader
                        title="contacts"
                        subtitle="build unparalleled relationships"
                        description="Learn more about your contacts and monitor trends to target the right groups with relevant content."
                        handleDownloadClick={handleDownloadClick}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <ContactsCard />
                </Grid>
                <Grid item xs={12} md={8}>
                    <SubscribersOverTimeCard />
                </Grid>
                <Grid item xs={12} md={4}>
                    <SubscriptionStatusCard />
                </Grid>
                <Grid item xs={12} md={8}>
                    <UnsubscribesCard />
                </Grid>
                <Grid item xs={12} md={4}>
                    <DomainsCard />
                </Grid>
                <Grid item xs={12}>
                    <LocationCard />
                </Grid>
                <Grid item xs={12} md={8}>
                    <AgeCard />
                </Grid>
                <Grid item xs={12} md={4}>
                    <SexCard />
                </Grid>
            </Grid>
        </div>
    );
};

export default ContactsDashboard;
