import { MenuItem } from '@mui/material';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { Template } from 'domains/content/types';
import Autocomplete from 'domains/core/components/Autocomplete';
import CreateNewDropDownItem from 'domains/core/components/CreateNewDropDownItem';
import { URLPaths } from 'models/enums';
import { AutocompleteOption } from '../Autocomplete/Autocomplete';

const createNewOption = { index: 0, value: undefined as any, label: 'Create New' };

const AutocompleteDirectMailContent = () => {
    const history = useHistory();

    const [contentInputValue, setContentInputValue] = useState('');

    const handleCreateNew = () => history.push(`${URLPaths.CONTENT_CREATE_DIRECT_MAIL}?id=default`);

    // eslint-disable-next-line arrow-body-style
    const getLeadingIcon = () => {
        // TODO
        return <></>;
    };

    const handleContentSelection = (_e: any, option: AutocompleteOption<Template>) => {
        // TODO
    };

    return (
        <Autocomplete
            data-test="content-autocomplete"
            data-testid="content-autocomplete"
            freeSolo={false}
            fullWidth
            getOptionLabel={(option) => option.label ?? ''}
            inputValue={contentInputValue ?? ''}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            label="Content"
            leadingIcon={getLeadingIcon()}
            onChange={handleContentSelection}
            onInputChange={(_e, inputValue) => setContentInputValue(inputValue)}
            options={[]}
            placeholder="Select or create content"
            renderOption={({ className, ...rest }, { index, label, value }) => {
                if (label === createNewOption.label && index === 0) {
                    return (
                        <CreateNewDropDownItem
                            {...rest}
                            children={label}
                            key={index}
                            value={value}
                            onClick={handleCreateNew}
                        />
                    );
                } else {
                    return <MenuItem {...rest} children={label} key={value.id} value={value} />;
                }
            }}
            type="string"
            value={null}
        />
    );
};

export default AutocompleteDirectMailContent;
