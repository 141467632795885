import { MailOutline } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { CustomCampaignSteps } from 'domains/campaigns/components/CustomCampaign/CustomCampaign';
import {
    setTemplateId,
    setPreHeader,
    setSenderProfileId,
    setSubjectLine,
} from 'domains/campaigns/components/CustomCampaign/customCampaignActions';
import { CampaignStateTypes } from 'domains/campaigns/components/CustomCampaign/customCampaignReducer';
import { CampaignChannels, SplitTypes } from 'domains/campaigns/types';
import AutocompleteContent from 'domains/core/components/AutocompleteContent';
import { AutocompleteOption } from 'domains/core/components/Autocomplete/Autocomplete';
import FormLayout from 'domains/core/components/FormLayout';
import { Template } from 'domains/content/types';
import useTemplates from 'hooks/queries/useTemplates';
import AdditionalContentFields from './AdditionalContentFields';

type Props = {
    activeSendIndex: number;
    campaignState: CampaignStateTypes;
    contentPreviewId?: number;
    dispatchCampaignAction: React.Dispatch<any>;
    setContentPreviewId: (id: number) => void;
    setIsContinueEnabled: (isContinueEnabled: boolean) => void;
    setNextStep: (step: CustomCampaignSteps) => void;
};

const EmailContentStep = ({
    activeSendIndex,
    campaignState,
    contentPreviewId,
    dispatchCampaignAction,
    setContentPreviewId,
    setIsContinueEnabled,
    setNextStep,
}: Props) => {
    const currentSend = campaignState.sends[activeSendIndex];
    const currentSendIsEmail = currentSend?.channel === CampaignChannels.EMAIL;
    const { preHeader, senderProfileId, subjectLine, templateId } = currentSendIsEmail && currentSend;

    const [contentInputValue, setContentInputValue] = useState('');
    const [selectedContentOption, setSelectedContentOption] = useState<AutocompleteOption<Template>>();

    const { data: templates } = useTemplates();

    useEffect(() => {
        if (!currentSendIsEmail) return;

        if (currentSend.splitType === SplitTypes.MEETS) {
            setNextStep(CustomCampaignSteps.SELECT_PATH_ACTION);
        } else {
            setNextStep(CustomCampaignSteps.CHOOSE_NEXT_STEP);
        }
        // The dependency array is empty because this useEffect should run exactly once after the initial rendering of the component.
        // The hook is not dependent on any props or state variables to re-run. This is intended to perform the initial setup based on
        // the `activeSendIndex` value when the component mounts without reacting to any further updates or re-renders.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const isEnabled =
            !!templateId &&
            !!senderProfileId &&
            subjectLine.length >= 3 &&
            (!preHeader.length || preHeader.length >= 3);
        setIsContinueEnabled(isEnabled);
    }, [preHeader.length, senderProfileId, setIsContinueEnabled, subjectLine.length, templateId]);

    useEffect(() => {
        if (templateId) {
            const selectedTemplate = templates?.find((template: Template) => template.id === templateId);
            const templateOption = { index: 1, label: selectedTemplate.name, value: selectedTemplate };

            setSelectedContentOption(templateOption);
            setContentInputValue(templateOption.label);
        }
    }, [templateId, templates]);

    const clearContentFields = () => {
        dispatchCampaignAction(setTemplateId(null, activeSendIndex));
        dispatchCampaignAction(setSenderProfileId(null, activeSendIndex));
        dispatchCampaignAction(setSubjectLine('', activeSendIndex));
        dispatchCampaignAction(setPreHeader('', activeSendIndex));
    };

    const updateContentFields = (option: AutocompleteOption<Template>) => {
        dispatchCampaignAction(setTemplateId(option.value.id, activeSendIndex));
        dispatchCampaignAction(setSenderProfileId(option.value.senderProfileId, activeSendIndex));
        dispatchCampaignAction(setSubjectLine(option.value.subjectLine ?? '', activeSendIndex));
        dispatchCampaignAction(setPreHeader(option.value.preHeader ?? '', activeSendIndex));
    };

    const handleContentSelection = (e: any, option: AutocompleteOption<Template>) => {
        if (option) {
            updateContentFields(option);
        } else {
            clearContentFields();
            setSelectedContentOption(null);
        }
    };

    const guidingText =
        activeSendIndex === 0
            ? 'Select the first email you want your audience to receive. Keep the goal of your Curation in mind and choose content that best supports your objective.'
            : 'Focus on the goal of the customer interaction and choose content from the dropdown that supports your objective.';

    const handlePreviewClick = () => {
        setContentPreviewId(!!contentPreviewId ? null : templateId);
    };

    if (!currentSendIsEmail) return;

    return (
        <FormLayout header="select content" stepName="email" icon={<MailOutline />} guidingText={guidingText}>
            <Stack rowGap={4}>
                <AutocompleteContent
                    isPreviewVisible={!!contentPreviewId}
                    onChange={handleContentSelection}
                    onEmailPreviewClick={handlePreviewClick}
                    onInputChange={(_e, inputValue) => setContentInputValue(inputValue)}
                    textfieldInput={contentInputValue}
                    value={selectedContentOption ?? null}
                />
                <AdditionalContentFields
                    activeSendIndex={activeSendIndex}
                    currentSend={currentSend}
                    dispatchCampaignAction={dispatchCampaignAction}
                />
            </Stack>
        </FormLayout>
    );
};

export default EmailContentStep;
