import { ArrowBack } from '@mui/icons-material';
import { Stack, IconButton, Typography } from '@mui/material';
import { URLPaths } from 'models/enums';
import { useHistory } from 'react-router';

const ContentPageBuilderPage = () => {
    const history = useHistory();

    const goToContentPage = () => history.push(`${URLPaths.CONTENT}?type=page`);

    return (
        <Stack height="100%" mt={8} mb={4} rowGap={4}>
            <Stack direction="row" alignItems="center" columnGap={1}>
                <IconButton aria-label="back" size="large" onClick={goToContentPage}>
                    <ArrowBack />
                </IconButton>
                <Typography data-testid="page-header" data-test="page-header" variant="h6" sx={{ pb: 0.75 }}>
                    Direct Mail
                </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center"></Stack>
            <Stack height="100%">Direct Mail Editor</Stack>
        </Stack>
    );
};

export default ContentPageBuilderPage;
