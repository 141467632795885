import { Grid } from '@mui/material';
import DashboardHeader from '../DashboardHeader';
import Filters from './Filters';
import { useEffect, useState } from 'react';
import { FiltersForApiState, FilterValuesState } from '../filtering/types';
import { CuredApiPaths } from 'models/enums';
import useFilteredData from '../filtering/useFilteredData';
import UniqueClicksByDayCard from './cards/UniqueClicksByDayCard';
import ValueSummaryByCurationCard from './cards/ValueSummaryByCurationCard';
import ValueOverviewStatsCards from './cards/ValueOverviewStatsCards';
import AudienceAndConversionCards from './cards/AudienceAndConversionCards';
import EventsBarGraphCard from './cards/EventsBarGraphCard';
import ValueBarGraphCard from './cards/ValueBarGraphCard';
import { BarGraphProps } from 'pages/InsightsPage/visualizations/BarGraph/BarGraph';

export type StatsDataProps = {
    events: BarGraphProps['data'];
    stats: {
        actual_click_rate: number;
        actual_events: number;
        actual_value: number;
        audience: number;
        conversion_value: number;
        estimated_click_rate: number;
        estimated_events: number;
        estimated_value: number;
        forecasted_click_rate: number;
        forecasted_events: number;
        forecasted_value: number;
    };
    values: BarGraphProps['data'];
};

const ValueOverviewDashboard = () => {
    // This state value is tracking the input values of the fields used for filtering.
    const [filterValues, setFilterValues] = useState<FilterValuesState>({
        selectedSendsValue: [],
        selectedSpecialtiesValue: [],
    });

    const sendsForApi = filterValues.selectedSendsValue?.map((send) => send.send_id);

    // This state value is tracking what's sent to the API for filtering, which may not
    // match the field values as filters are not applied until a button is clicked.
    const [filtersForApi, setFiltersForApi] = useState<FiltersForApiState>({
        sends: sendsForApi,
        specialties: filterValues.selectedSpecialtiesValue,
    });

    // This handles clicking on the button to apply filters by setting the filtersForApi
    // state to match the input state values.
    const handleApplyFilters = () => {
        setFiltersForApi({
            sends: sendsForApi,
            specialties: filterValues.selectedSpecialtiesValue,
        });
    };

    // This function will handle the API call to get the downloadable report according to the
    // filters applied in the current state.
    const handleDownloadClick = () => {};

    // Getting data for various visualizations on this dashboard
    const { getData, isError, isLoading, data } = useFilteredData({
        key: 'value-overview-dashboard/stats',
        path: CuredApiPaths.REPORT_VALUE_STATS,
    });

    useEffect(() => {
        getData(filtersForApi);
    }, [filtersForApi, getData]);

    const statsData = data as StatsDataProps;

    return (
        <div style={{ paddingBottom: '32px' }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Filters
                        filterValues={filterValues}
                        handleApplyFilters={handleApplyFilters}
                        setFilterValues={setFilterValues}
                    />
                </Grid>
                <Grid item xs={12} md={8}>
                    <DashboardHeader
                        title="value overview"
                        subtitle="track the impact of your marketing efforts"
                        handleDownloadClick={handleDownloadClick}
                    />
                </Grid>
                <AudienceAndConversionCards
                    audience={statsData?.stats.audience}
                    conversion={statsData?.stats.conversion_value}
                    isError={isError}
                    isLoading={isLoading}
                />
                <ValueOverviewStatsCards data={statsData?.stats} isError={isError} isLoading={isLoading} />
                <Grid item xs={12}>
                    <UniqueClicksByDayCard filtersForApi={filtersForApi} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <EventsBarGraphCard data={statsData?.events} isError={isError} isLoading={isLoading} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ValueBarGraphCard data={statsData?.values} isError={isError} isLoading={isLoading} />
                </Grid>
                <Grid item xs={12}>
                    <ValueSummaryByCurationCard filtersForApi={filtersForApi} />
                </Grid>
            </Grid>
        </div>
    );
};

export default ValueOverviewDashboard;
