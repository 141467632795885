import { CallSplit, DoNotDisturb } from '@mui/icons-material';
import { channelIcons } from 'domains/campaigns/pages/CurationsPage/utils';
import { CampaignChannels, SplitActions } from 'domains/campaigns/types';
import { Template } from 'domains/content/types';
import useTemplates from 'hooks/queries/useTemplates';
import { CustomCampaignSteps } from '../../CustomCampaign';
import CustomCampaignNavigationButton from '../CustomCampaignNavigationButton';
import { CampaignSend } from '../../customCampaignReducer';
import { getValueWithNameAndScheduleInfo } from './utils';

type Props = {
    activeSendIndex: number;
    activeStep: CustomCampaignSteps;
    index: number;
    send: CampaignSend;
    setActiveSendIndex: (index: number) => void;
    setActiveStep: (step: CustomCampaignSteps) => void;
};

const CustomCampaignNavigationSend = ({
    activeSendIndex,
    activeStep,
    index,
    send,
    setActiveSendIndex,
    setActiveStep,
}: Props) => {
    const isEmailSend = send.channel === CampaignChannels.EMAIL;
    const isDirectMailSend = send.channel === CampaignChannels.DIRECT_MAIL;
    const isSMSSend = send.channel === CampaignChannels.SMS;

    const { data: templates } = useTemplates({
        enabled: isEmailSend,
    });

    const isSplitSendWithoutAction = isEmailSend && !!send.splitType && !send.splitAction;

    if (isSplitSendWithoutAction)
        return (
            <CustomCampaignNavigationButton
                disabled={!send.splitAction}
                isActive={activeStep === CustomCampaignSteps.SELECT_PATH_ACTION && index === activeSendIndex}
                icon={<CallSplit />}
                label={send.splitType}
                onClick={() => {
                    setActiveSendIndex(index);
                    setActiveStep(CustomCampaignSteps.SELECT_PATH_ACTION);
                }}
            />
        );

    // These bools and helper funcs are used for both direct mail and email sends
    const isActiveSend =
        (activeStep === CustomCampaignSteps.SELECT_CONTENT || activeStep === CustomCampaignSteps.SCHEDULE_CURATION) &&
        index === activeSendIndex;
    const shouldShowScheduleInfo = index === 0 && !!send?.scheduledTimestamp;

    const SendIcon = channelIcons[send.channel];

    if (isSMSSend && send.content?.length) {
        const name = `${send.content.slice(0, 20)}${send.content.length > 20 ? '...' : ''}`;
        const contentValue = getValueWithNameAndScheduleInfo(name, send, shouldShowScheduleInfo);

        return (
            <CustomCampaignNavigationButton
                isActive={isActiveSend}
                icon={<SendIcon />}
                label="SMS"
                onClick={() => {
                    setActiveSendIndex(index);
                    setActiveStep(CustomCampaignSteps.SELECT_CONTENT);
                }}
                value={contentValue}
            />
        );
    }

    if (isDirectMailSend && send.content?.id) {
        const contentValue = getValueWithNameAndScheduleInfo(send.content?.name, send, shouldShowScheduleInfo);

        return (
            <CustomCampaignNavigationButton
                isActive={isActiveSend}
                icon={<SendIcon />}
                label="direct mail"
                labelStyles={{ minWidth: '94px' }}
                onClick={() => {
                    setActiveSendIndex(index);
                    setActiveStep(CustomCampaignSteps.SELECT_CONTENT);
                }}
                value={contentValue}
            />
        );
    }

    // We want to render a "send" in the nav if there is a send (template has been selected)
    // or if a condtional split will have a send, even before a template has been selected
    if (isEmailSend && (send.templateId || send.splitAction === SplitActions.SEND)) {
        const currentTemplate = templates?.find((template: Template) => template.id === send?.templateId);
        const emailValue = getValueWithNameAndScheduleInfo(currentTemplate?.name, send, shouldShowScheduleInfo);

        return (
            <>
                <div>{send.splitType}</div>
                <CustomCampaignNavigationButton
                    isActive={isActiveSend}
                    icon={<SendIcon />}
                    label="email"
                    onClick={() => {
                        setActiveSendIndex(index);
                        setActiveStep(
                            send.splitType ? CustomCampaignSteps.SELECT_PATH_ACTION : CustomCampaignSteps.SELECT_CONTENT
                        );
                    }}
                    value={emailValue}
                />
            </>
        );
    }

    if (isEmailSend && send.splitAction === SplitActions.EXIT)
        return (
            <>
                <div>{send.splitType}</div>
                <CustomCampaignNavigationButton
                    isActive={activeStep === CustomCampaignSteps.SELECT_PATH_ACTION && index === activeSendIndex}
                    icon={<DoNotDisturb />}
                    label="exit curation"
                    onClick={() => {
                        setActiveSendIndex(index);
                        setActiveStep(CustomCampaignSteps.SELECT_PATH_ACTION);
                    }}
                />
            </>
        );
};

export default CustomCampaignNavigationSend;
