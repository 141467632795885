import { useRef, useState } from 'react';
import { ButtonGroup, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Button from 'domains/core/components/Button';

export type DropdownButtonOption = {
    dataTestId?: string;
    label: React.ReactNode;
    onClick: () => void;
    selected?: boolean;
};

type Props = {
    label: string;
    options: DropdownButtonOption[];
    ariaLabel?: string;
    backgroundColor?: string;
    fontColor?: string;
    icon?: React.ReactNode;
    sx?: SxProps<Theme>;
    [other: string]: any;
};

/**
 * A button with a dropdown of options.
 * Based off of https://v4.mui.com/components/button-group/#split-button with modifications.
 * @param label The label for the button.
 * @param options The options to be shown in the dropdown.
 * @param backgroundColor The background color of the button.
 * @param ariaLabel The aria label for the component.
 * @param fontColor The font color of the text in the button.
 * @param icon The icon shown on the right side of the button.
 * @param sx The MUI sx prop

 * @returns The React node created by this component.
 */

const DropdownButton = ({
    ariaLabel,
    icon = <ExpandMore />,
    label,
    backgroundColor,
    fontColor,
    options,
    sx,
    ...other
}: Props) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);

    const onClickButton = () => setOpen((prevOpen) => !prevOpen);

    const onClickMenuItem = (_event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
        setOpen(false);
        options[index].onClick();
    };

    const onClickAway = (event: MouseEvent | TouchEvent): void => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    return (
        <>
            <ButtonGroup ref={anchorRef} aria-label="split button">
                <Button
                    aria-controls={open ? 'dropdown-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="menu"
                    aria-label={ariaLabel}
                    onClick={onClickButton}
                    endIcon={icon}
                    sx={sx}
                    {...other}
                >
                    {label}
                </Button>
            </ButtonGroup>
            <Popper
                sx={{
                    minWidth: anchorRef?.current?.offsetWidth,
                    zIndex: 10,
                    pt: 0.25,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                popperOptions={{ placement: 'bottom-start' }}
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={onClickAway}>
                                <MenuList>
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={index}
                                            data-testid={option?.dataTestId ?? null}
                                            onClick={(event) => onClickMenuItem(event, index)}
                                            selected={option.selected}
                                            sx={{
                                                textTransform: 'capitalize',
                                                '&.Mui-selected': {
                                                    backgroundColor: 'white',
                                                    color: 'action.active',
                                                },
                                            }}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

export default DropdownButton;
