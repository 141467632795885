import { MenuItem } from '@mui/material';
import { useState } from 'react';
import Autocomplete from 'domains/core/components/Autocomplete';
import CreateNewDropDownItem from 'domains/core/components/CreateNewDropDownItem';
import { SettingsPhoneOutlined } from '@mui/icons-material';

const iconStyles = {
    color: 'secondary.main',
    '&:hover': {
        color: 'secondary.main',
        cursor: 'pointer',
    },
};

const createNewOption = { index: 0, value: undefined as any, label: 'Create New' };

const AutocompleteVoiceCallContent = () => {
    //TODO : const history = useHistory();

    const [contentInputValue, setContentInputValue] = useState('');

    /*
    //TODO - get voice call content options
    const { isSuccess: isVoiceCallContentSuccess, data: voiceCallMessages } = useVoiceCallContent();

    const voiceCallContentOptions: AutocompleteOption<VoiceCallContent>[] = useMemo(
        () =>
            isVoiceCallContentSuccess
                ? voiceCallMessages.map((voiceCallMessage: VoiceCallContent, index: number) => ({
                      index: index + 1,
                      value: voiceCallMessage,
                      label: voiceCallMessage.name,
                  }))
                : [],
        [isVoiceCallContentSuccess, voiceCallMessages]
    );
    const options = [createNewOption, ...voiceCallContentOptions];
    */

    const options = [createNewOption];

    return (
        <Autocomplete
            freeSolo={false}
            fullWidth
            getOptionLabel={(option) => option.label ?? ''}
            inputValue={contentInputValue ?? ''}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            label="Content"
            leadingIcon={
                contentInputValue ? (
                    <SettingsPhoneOutlined aria-label="preview content" sx={iconStyles} onClick={() => {}} />
                ) : null
            }
            onChange={() => {}}
            onInputChange={(_e, inputValue) => setContentInputValue(inputValue)}
            options={options ?? []}
            placeholder="Select or create content"
            renderOption={({ className, ...rest }, { index, label, value }) => {
                if (label === createNewOption.label && index === 0) {
                    return (
                        <CreateNewDropDownItem
                            {...rest}
                            children={label}
                            key={index}
                            value={value}
                            onClick={() => {}}
                        />
                    );
                } else {
                    return <MenuItem {...rest} children={label} key={value.id} value={value} />;
                }
            }}
            type="string"
            value={null}
        />
    );
};

export default AutocompleteVoiceCallContent;
