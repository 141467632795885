import { Grid } from '@mui/material';
import { useState } from 'react';
import NewOptOutsOverTimeCard from './cards/NewOptOutsOverTimeCard';
import NewSubsOverTimeCard from './cards/NewSubsOverTimeCard';
import SMSMetricsBySendCard from './cards/SMSMetricsBySendCard';
import SMSStatsCards from './cards/SMSStatsCards';
import TotalSendsOverTimeCard from './cards/TotalSendsOverTimeCard';
import DashboardHeader from '../DashboardHeader';
import { oneYearAgo, today } from '../filtering/DateFilter/DateFilter';
import { FiltersForApiState, FilterValuesState } from '../filtering/types';
import { getTimeframeForApi } from '../filtering/utils';
import Filters from './Filters';

const SMSDashboard = () => {
    const end = today;
    const start = oneYearAgo;
    // This state value is tracking the input values of the fields used for filtering.
    const [filterValues, setFilterValues] = useState<FilterValuesState>({
        selectedSendsValue: [],
        filterTimeframe: { start, end },
        filterRelativeTimeframe: {
            direction: null,
            duration: null,
            unit: null,
        },
    });
    const [isRelativeTimeframe, setIsRelativeTimeframe] = useState(false);

    const sendsForApi = filterValues.selectedSendsValue?.map((send) => send?.send_id);
    const timeframeForApi = getTimeframeForApi(isRelativeTimeframe, filterValues);

    // This state value is tracking what's sent to the API for filtering, which may not
    // match the field values as filters are not applied until a button is clicked.
    const [filtersForApi, setFiltersForApi] = useState<FiltersForApiState>({
        sends: sendsForApi,
        timeframe: timeframeForApi,
    });

    // This handles clicking on the button to apply filters by setting the filtersForApi
    // state to match the input state values.
    const handleApplyFilters = () => {
        setFiltersForApi({
            sends: sendsForApi,
            timeframe: timeframeForApi,
        });
    };

    // This function will handle the API call to get the downloadable report according to the
    // filters applied in the current state.
    const handleDownloadClick = () => {};

    return (
        <div style={{ paddingBottom: '32px' }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Filters
                        filterValues={filterValues}
                        handleApplyFilters={handleApplyFilters}
                        isRelativeTimeframe={isRelativeTimeframe}
                        setFilterValues={setFilterValues}
                        setIsRelativeTimeframe={setIsRelativeTimeframe}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DashboardHeader
                        title="sms messaging"
                        subtitle="take action with cured insights"
                        description="Filter on specific SMS Curations to quickly identify what's working to improve future sends."
                        handleDownloadClick={handleDownloadClick}
                    />
                </Grid>
                <SMSStatsCards filtersForApi={filtersForApi} />
                <Grid item xs={12}>
                    <NewSubsOverTimeCard filtersForApi={filtersForApi} />
                </Grid>
                <Grid item xs={12}>
                    <TotalSendsOverTimeCard filtersForApi={filtersForApi} />
                </Grid>
                <Grid item xs={12}>
                    <NewOptOutsOverTimeCard filtersForApi={filtersForApi} />
                </Grid>
                <Grid item xs={12}>
                    <SMSMetricsBySendCard filtersForApi={filtersForApi} />
                </Grid>
            </Grid>
        </div>
    );
};

export default SMSDashboard;
