import { InfoOutlined } from '@mui/icons-material';
import { FormControl, FormControlLabel, FormHelperText, Stack, Switch, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { RelativeTimeframeUnit } from 'models/types';
import Select from 'domains/core/components/Select';
import { setReEntryDetails } from 'domains/campaigns/components/CustomCampaign/customCampaignActions';
import TextField from 'domains/core/components/TextField';
import { ReEntryFields } from 'domains/campaigns/types';

const timeframeDimensions = [
    { value: RelativeTimeframeUnit.HOUR, label: 'Hours' },
    { value: RelativeTimeframeUnit.DAY, label: 'Days' },
];

const tooltipMessage =
    'Allows contacts who qualify to receive this curation again after a set time, based on their last send date.';

const getErrorMessage = (reEntryValue?: string, reEntryDimension?: string): string => {
    const reEntryValueAsNum = reEntryValue && Number(reEntryValue);

    if (reEntryValueAsNum === 0) {
        return 'The Re-Entry Period must be greater than 0.';
    }

    if (!reEntryValue || !reEntryDimension) return '';

    const reEntryPeriodIsValid =
        (reEntryDimension === RelativeTimeframeUnit.DAY && reEntryValueAsNum < 1095) ||
        (reEntryDimension === RelativeTimeframeUnit.HOUR && reEntryValueAsNum < 26280);

    if (reEntryPeriodIsValid) return '';

    return 'Re-entry period cannot be more than 3 years in the future';
};

const reEntryHeaderStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
    flexDirection: 'row',
};

const infoIconStyle = {
    fontSize: '14px',
    marginTop: '4px',
    marginLeft: '4px',
};

const errorMessageStyle = {
    alignSelf: 'self-start',
    marginTop: '8px',
    marginLeft: '0px !important',
};

type Props = {
    dispatchCampaignAction: React.Dispatch<any>;
    reentryDetails?: ReEntryFields;
    setIsContinueEnabled: (isContinueEnabled: boolean) => void;
};

const ContactReEntrySchedule = ({ dispatchCampaignAction, reentryDetails, setIsContinueEnabled }: Props) => {
    const [reEntryValue, setReEntryValue] = useState(reentryDetails?.value?.toString() || '');
    const [reEntryDimension, setReEntryDimension] = useState(reentryDetails?.dimension || '');
    const [checkedReEntry, setCheckedReEntry] = useState(!!reEntryDimension && !!reEntryValue);

    const errorMessage = getErrorMessage(reEntryValue, reEntryDimension);

    const handleReEntryToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setCheckedReEntry(true);
        } else {
            setCheckedReEntry(false);
            setReEntryValue('');
            setReEntryDimension('');
            dispatchCampaignAction(setReEntryDetails({ dimension: '', value: null }));
        }
    };

    const handleValueChange = (e: any) => {
        // Don't allow decimal or negative values.
        if (e.target.value.includes('.') || e.target.value.includes('-')) return;

        const reEntryValueAsNum = Number(e.target.value);

        // Allows only 5 digit input
        if (reEntryValueAsNum < 100000) {
            setReEntryValue(e.target.value);
        }
    };

    useEffect(() => {
        setIsContinueEnabled(checkedReEntry ? !!reEntryValue && !!reEntryDimension && !errorMessage : true);
    }, [reEntryValue, reEntryDimension, checkedReEntry, errorMessage, setIsContinueEnabled]);

    useEffect(() => {
        if (!!reEntryValue && !!reEntryDimension) {
            dispatchCampaignAction(setReEntryDetails({ dimension: reEntryDimension, value: Number(reEntryValue) }));
        }
        // We only want this to run if the re entry value or dimensions changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reEntryValue, reEntryDimension]);

    return (
        <FormControl>
            <Stack sx={reEntryHeaderStyle}>
                <Typography variant="body2">Contact Re-Entry</Typography>
                <Tooltip title={tooltipMessage}>
                    <InfoOutlined sx={infoIconStyle} />
                </Tooltip>
            </Stack>
            <FormControlLabel
                control={
                    <Switch
                        color="secondary"
                        size="small"
                        checked={checkedReEntry}
                        onChange={handleReEntryToggle}
                        sx={{ marginLeft: '6px' }}
                    />
                }
                label={<Typography variant="body2">Contacts can re-enter this Curation</Typography>}
            />
            {checkedReEntry && (
                <Stack marginTop="8px">
                    <Stack spacing={2} direction="row">
                        <TextField
                            label="Delay"
                            type="number"
                            value={reEntryValue}
                            onChange={handleValueChange}
                            error={!!errorMessage}
                        />
                        <Select
                            label="Timeframe"
                            values={timeframeDimensions}
                            onChange={(e: any) => {
                                setReEntryDimension(e.target.value);
                            }}
                            value={reEntryDimension}
                        />
                    </Stack>
                    {errorMessage && (
                        <FormHelperText error sx={errorMessageStyle}>
                            {errorMessage}
                        </FormHelperText>
                    )}
                </Stack>
            )}
        </FormControl>
    );
};

export default ContactReEntrySchedule;
