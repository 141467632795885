import {
    ButtonProps,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import Button from 'domains/core/components/Button';

export type Props = {
    icon: React.ReactNode;
    title: string;
    body: React.ReactNode;
    buttonOneText: string;
    buttonTwoText: string;
    isOpen: boolean;
    buttonOneOnClick: () => void;
    buttonTwoOnClick: () => void;
    onClose: () => void;
    actionIcon?: JSX.Element;
    actionIconTooltip?: string;
    buttonOneProps?: ButtonProps;
    buttonTwoProps?: ButtonProps;
    dialogProps?: DialogProps;
};

const DialogStandard = ({
    icon,
    title,
    body,
    buttonOneText,
    buttonTwoText,
    isOpen,
    buttonOneOnClick,
    buttonTwoOnClick,
    onClose,
    actionIcon,
    actionIconTooltip,
    buttonOneProps,
    buttonTwoProps,
    ...dialogProps
}: Props) => (
    <Dialog
        maxWidth="sm"
        fullWidth
        open={isOpen}
        onClose={onClose}
        aria-labelledby="curation-success-modal"
        {...dialogProps}
    >
        <DialogTitle>
            <Stack direction="row" alignItems="flex-end" spacing={2}>
                {icon}
                <Typography variant="h6">{title}</Typography>
            </Stack>
        </DialogTitle>
        <DialogContent>{body}</DialogContent>
        <DialogActions>
            <Stack direction="row" justifyContent="space-between" alignItems="flex-end" width="100%">
                {actionIcon && actionIconTooltip && <Tooltip title={actionIconTooltip}>{actionIcon}</Tooltip>}
                <Stack direction="row" columnGap={2}>
                    <Button variant="text" onClick={() => buttonOneOnClick()} {...buttonOneProps}>
                        {buttonOneText}
                    </Button>
                    <Button variant="contained" onClick={() => buttonTwoOnClick()} {...buttonTwoProps}>
                        {buttonTwoText}
                    </Button>
                </Stack>
            </Stack>
        </DialogActions>
    </Dialog>
);

export default DialogStandard;
