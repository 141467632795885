import { useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { resetContent } from 'domains/campaigns/components/CustomCampaign/customCampaignActions';
import { DirectMailSendInputState } from 'domains/campaigns/types';
import AutocompleteDirectMailContent from 'domains/core/components/AutocompleteDirectMailContent';
import FileUploader from '../FileUploader';

enum ContentStepAction {
    SELECT = 'select',
    UPLOAD = 'upload',
}

export type Props = {
    activeSendIndex: number;
    currentSend: DirectMailSendInputState;
    dispatchCampaignAction: React.Dispatch<any>;
};

const SelectOrUploadWrapper = ({ activeSendIndex, currentSend, dispatchCampaignAction }: Props) => {
    const fileName = currentSend?.content?.name;

    // TODO: do this better?
    const isUploadedContentPDF = fileName.includes('.pdf');
    const [contentStepAction, setContentStepAction] = useState(
        isUploadedContentPDF ? ContentStepAction.UPLOAD : ContentStepAction.SELECT
    );

    const renderContentSelection = () => {
        if (contentStepAction === ContentStepAction.UPLOAD) {
            return (
                <FileUploader
                    activeSendIndex={activeSendIndex}
                    dispatchCampaignAction={dispatchCampaignAction}
                    fileName={currentSend?.content?.name}
                />
            );
        }

        return <AutocompleteDirectMailContent />;
    };

    return (
        <>
            <ToggleButtonGroup
                value={contentStepAction}
                exclusive
                onChange={(_e, value) => {
                    if (!value) return;
                    setContentStepAction(value);
                    dispatchCampaignAction(resetContent(activeSendIndex));
                }}
                aria-label="select or upload content"
            >
                <ToggleButton value={ContentStepAction.SELECT} aria-label="select or create content">
                    Select
                </ToggleButton>
                <ToggleButton value={ContentStepAction.UPLOAD} aria-label="upload from computer">
                    Upload
                </ToggleButton>
            </ToggleButtonGroup>
            {renderContentSelection()}
        </>
    );
};

export default SelectOrUploadWrapper;
