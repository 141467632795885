/* eslint-disable @typescript-eslint/no-unused-vars */
import { Stack } from '@mui/material';
import { useEffect } from 'react';
import { CustomCampaignSteps } from 'domains/campaigns/components/CustomCampaign/CustomCampaign';
import { setSenderProfileId } from 'domains/campaigns/components/CustomCampaign/customCampaignActions';
import { CampaignStateTypes } from 'domains/campaigns/components/CustomCampaign/customCampaignReducer';
import { channelIcons } from 'domains/campaigns/pages/CurationsPage/utils';
import { DirectMailSendInputState } from 'domains/campaigns/types';
import { DirectMailSenderProfile } from 'domains/content/types';
import FormLayout from 'domains/core/components/FormLayout';
import InputLabel from 'domains/core/components/InputLabel';
import Select from 'domains/core/components/Select';
import useSenderProfiles from 'hooks/queries/useSenderProfiles';
import theme from 'theme';
import { formatAddressLabel } from './utils';
import SelectOrUploadWrapper from './SelectOrUploadWrapper';

const SAMPLE_PDF_URL = '/assets/sample.pdf';
const GUIDING_TEXT = (
    <>
        Focus on the goal of your customer interaction and select or upload content that best aligns with your
        objective. To ensure an uploaded file meets the required format, you can download a sample file{' '}
        <a
            href={SAMPLE_PDF_URL}
            download="sample.pdf"
            style={{ color: theme.palette.secondary.main, textDecoration: 'underline' }}
        >
            here
        </a>{' '}
        for reference.
    </>
);

type Props = {
    activeSendIndex: number;
    campaignState: CampaignStateTypes;
    contentPreviewId?: number;
    dispatchCampaignAction: React.Dispatch<any>;
    setContentPreviewId: (id: number) => void;
    setIsContinueEnabled: (isContinueEnabled: boolean) => void;
    setNextStep: (step: CustomCampaignSteps) => void;
};

const DirectMailContentStep = ({
    activeSendIndex,
    campaignState,
    contentPreviewId, // TODO: When previewing content is ready
    dispatchCampaignAction,
    setContentPreviewId, // TODO: When previewing content is ready
    setIsContinueEnabled,
    setNextStep,
}: Props) => {
    const currentSend = campaignState.sends[activeSendIndex];
    const { content, senderProfileId } = currentSend as DirectMailSendInputState;

    const { data: senderProfiles } = useSenderProfiles({ type: 'direct-mail' });

    useEffect(() => {
        // Direct mail curations don't support delayed sends yet, but we're leaving this here for when it does
        if (activeSendIndex > 0) {
            setNextStep(CustomCampaignSteps.CHOOSE_NEXT_STEP);
        } else {
            setNextStep(CustomCampaignSteps.SCHEDULE_CURATION);
        }
        // The dependency array is empty because this useEffect should run exactly once after the initial rendering of the component.
        // The hook is not dependent on any props or state variables to re-run. This is intended to perform the initial setup based on
        // the `activeSendIndex` value when the component mounts without reacting to any further updates or re-renders.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const isEnabled = !!content.id && !!senderProfileId;
        setIsContinueEnabled(isEnabled);
    }, [content.id, senderProfileId, setIsContinueEnabled]);

    const senderProfilesValues =
        senderProfiles?.map((profile: DirectMailSenderProfile) => ({
            value: profile.id,
            label: formatAddressLabel(profile),
        })) || [];

    const onSenderProfileChange = (e: any) =>
        dispatchCampaignAction(setSenderProfileId(e.target.value, activeSendIndex));

    const StepIcon = channelIcons.DIRECTMAIL;

    return (
        <FormLayout header="select content" icon={<StepIcon />} guidingText={GUIDING_TEXT} stepName="direct mail">
            <Stack rowGap={4}>
                <SelectOrUploadWrapper
                    activeSendIndex={activeSendIndex}
                    currentSend={currentSend as DirectMailSendInputState}
                    dispatchCampaignAction={dispatchCampaignAction}
                />
                <Select
                    fullWidth
                    label={<InputLabel label="Sender profile" labelIconTooltipText="Who is sending this?" />}
                    menuItemStyles={{ textTransform: 'none' }}
                    onChange={onSenderProfileChange}
                    required
                    value={senderProfileId ?? ''}
                    values={senderProfilesValues}
                />
            </Stack>
        </FormLayout>
    );
};

export default DirectMailContentStep;
