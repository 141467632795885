import { AccessTime, CallSplit, MailOutline, ScheduleSendOutlined, SwapCalls } from '@mui/icons-material';
import { FormControlLabel, Radio, RadioGroup, Tooltip } from '@mui/material';
import { ReactNode } from 'react';
import { CustomCampaignSteps } from 'domains/campaigns/components/CustomCampaign/CustomCampaign';
import FormLayout from 'domains/core/components/FormLayout';
import {
    disabledButtonStyles,
    iconStyles,
    labelStyles,
    sharedButtonStyles,
} from './CustomCampaignChooseNextStep/CustomCampaignChooseNextStep.styles';

export const MAX_SENDS_AMOUNT = 12;

export const getSteps = (getIsStepDisabled: (stepName: CustomCampaignSteps) => boolean, maxSendsReached: boolean) => [
    {
        disabled: getIsStepDisabled(CustomCampaignSteps.SELECT_CONTENT),
        disabledMessage: maxSendsReached
            ? `You've reached the maximum number of sends.`
            : 'Add a time delay before your next send.',
        icon: <MailOutline sx={iconStyles} />,
        label: 'email',
        value: CustomCampaignSteps.SELECT_CONTENT,
    },
    {
        disabled: getIsStepDisabled(CustomCampaignSteps.SET_TIME_DELAY),
        disabledMessage: maxSendsReached
            ? 'You’ve reached the maximum number of time delays.'
            : 'Choose an email or split before your next time delay.',
        icon: <AccessTime sx={iconStyles} />,
        label: 'time delay',
        value: CustomCampaignSteps.SET_TIME_DELAY,
    },
    {
        disabled: getIsStepDisabled(CustomCampaignSteps.CONDITIONAL_SPLIT),
        disabledMessage: maxSendsReached
            ? `You've reached the maximum number of splits.`
            : 'Add a time delay before your split.',
        icon: <CallSplit sx={iconStyles} />,
        label: 'conditional split',
        value: CustomCampaignSteps.CONDITIONAL_SPLIT,
    },
    {
        disabled: getIsStepDisabled(CustomCampaignSteps.SCHEDULE_CURATION),
        disabledMessage: 'Choose an action before you exit.',
        icon: <ScheduleSendOutlined sx={iconStyles} />,
        label: 'schedule & publish curation',
        value: CustomCampaignSteps.SCHEDULE_CURATION,
    },
];

export type step = {
    disabled?: boolean;
    disabledMessage?: string;
    icon: ReactNode;
    label: string;
    value: CustomCampaignSteps;
};

export const renderChooseStepsForm = (
    handleStepClick: (event: React.ChangeEvent<HTMLInputElement>) => void,
    selectedStep: string,
    steps: step[]
) => (
    <FormLayout header="choose next step" stepName="actions" icon={<SwapCalls />}>
        <RadioGroup onChange={handleStepClick} value={selectedStep}>
            {steps.map((step: step) => (
                <Tooltip key={step.value} title={step.disabled && step.disabledMessage}>
                    <FormControlLabel
                        control={<Radio sx={{ display: 'none' }} />}
                        label={
                            <span style={labelStyles}>
                                {step.icon}
                                {step.label}
                            </span>
                        }
                        sx={{
                            ...sharedButtonStyles,
                            ...(step.disabled && disabledButtonStyles),
                        }}
                        value={step.value}
                    />
                </Tooltip>
            ))}
        </RadioGroup>
    </FormLayout>
);
