import { Grid } from '@mui/material';
import { useState } from 'react';
import CurationStatsCards from './cards/CurationStatsCards';
import MessagesOverTimeCard from './cards/MessagesOverTimeCard';
import EngagementFunnel from './cards/EngagementFunnelCard';
import UniqueClicksCard from './cards/UniqueClicksCard';
import UniqueOpensCard from './cards/UniqueOpensCard';
import OpensByDeviceCard from './cards/OpensByDeviceCard';
import ClicksByDeviceCard from './cards/ClicksByDeviceCard';
import EngagementByCurationCard from './cards/EngagementByCurationCard';
import LinksByClickCard from './cards/LinksByClickCard';
import DashboardHeader from '../DashboardHeader';
import Filters from './Filters';
import { oneYearAgo, today } from '../filtering/DateFilter/DateFilter';
import { FiltersForApiState, FilterValuesState } from '../filtering/types';
import { getTimeframeForApi } from '../filtering/utils';

const CurationsDashboard = () => {
    const end = today;
    const start = oneYearAgo;
    // This state value is tracking the input values of the fields used for filtering.
    const [filterValues, setFilterValues] = useState<FilterValuesState>({
        includesUnsubscribes: false,
        selectedSendsValue: [],
        selectedCurationsValue: [],
        filterTimeframe: { start, end },
        filterRelativeTimeframe: {
            direction: null,
            duration: null,
            unit: null,
        },
    });
    const [isRelativeTimeframe, setIsRelativeTimeframe] = useState(false);

    const sendsForApi = filterValues.selectedSendsValue?.map((send) => send?.send_id);
    const campaignsForApi = filterValues.selectedCurationsValue?.map((campaign) => campaign?.campaign_id);
    const timeframeForApi = getTimeframeForApi(isRelativeTimeframe, filterValues);

    // This state value is tracking what's sent to the API for filtering, which may not
    // match the field values as filters are not applied until a button is clicked.
    const [filtersForApi, setFiltersForApi] = useState<FiltersForApiState>({
        unsubs: filterValues.includesUnsubscribes,
        sends: sendsForApi,
        campaigns: campaignsForApi,
        timeframe: timeframeForApi,
    });

    // This handles clicking on the button to apply filters by setting the filtersForApi
    // state to match the input state values.
    const handleApplyFilters = () => {
        setFiltersForApi({
            unsubs: filterValues.includesUnsubscribes,
            sends: sendsForApi,
            campaigns: campaignsForApi,
            timeframe: timeframeForApi,
        });
    };

    // This function will handle the API call to get the downloadable report according to the
    // filters applied in the current state.
    const handleDownloadClick = () => {};

    return (
        <div style={{ paddingBottom: '32px' }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Filters
                        filterValues={filterValues}
                        handleApplyFilters={handleApplyFilters}
                        isRelativeTimeframe={isRelativeTimeframe}
                        setFilterValues={setFilterValues}
                        setIsRelativeTimeframe={setIsRelativeTimeframe}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DashboardHeader
                        title="curations"
                        subtitle="take action with cured insights"
                        description="Filter on specific Curations to quickly identify what's working to improve future sends."
                        handleDownloadClick={handleDownloadClick}
                    />
                </Grid>
                <CurationStatsCards filtersForApi={filtersForApi} />
                <Grid item xs={12} md={8}>
                    <MessagesOverTimeCard filtersForApi={filtersForApi} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <EngagementFunnel filtersForApi={filtersForApi} />
                </Grid>
                <Grid item xs={12} md={8}>
                    <UniqueClicksCard filtersForApi={filtersForApi} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <OpensByDeviceCard filtersForApi={filtersForApi} />
                </Grid>
                <Grid item xs={12} md={8}>
                    <UniqueOpensCard filtersForApi={filtersForApi} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <ClicksByDeviceCard filtersForApi={filtersForApi} />
                </Grid>
                <Grid item xs={12}>
                    <EngagementByCurationCard filtersForApi={filtersForApi} />
                </Grid>
                <Grid item xs={12}>
                    <LinksByClickCard filtersForApi={filtersForApi} />
                </Grid>
            </Grid>
        </div>
    );
};

export default CurationsDashboard;
