import { Stack } from '@mui/material';
import { useEffect } from 'react';
import { CustomCampaignSteps } from 'domains/campaigns/components/CustomCampaign/CustomCampaign';
import { setCallerProfileId } from 'domains/campaigns/components/CustomCampaign/customCampaignActions';
import { CampaignStateTypes } from 'domains/campaigns/components/CustomCampaign/customCampaignReducer';
import { channelIcons } from 'domains/campaigns/pages/CurationsPage/utils';
import { VoiceCallSendInput } from 'domains/campaigns/types';
import { VoiceCallerProfile } from 'domains/content/types';
import FormLayout from 'domains/core/components/FormLayout';
import InputLabel from 'domains/core/components/InputLabel';
import Select from 'domains/core/components/Select';
import AutocompleteVoiceCallContent from 'domains/core/components/AutocompleteVoiceCallContent';

const GUIDING_TEXT =
    'Focus on the goal of the customer interaction and choose content from the dropdown that supports your objective.';

type Props = {
    activeSendIndex: number;
    campaignState: CampaignStateTypes;
    contentPreviewId?: number;
    dispatchCampaignAction: React.Dispatch<any>;
    setContentPreviewId: (id: number) => void;
    setIsContinueEnabled: (isContinueEnabled: boolean) => void;
    setNextStep: (step: CustomCampaignSteps) => void;
};

const VoiceCallContentStep = ({
    activeSendIndex,
    campaignState,
    contentPreviewId, // TODO: When previewing content is ready
    dispatchCampaignAction,
    setContentPreviewId, // TODO: When previewing content is ready
    setIsContinueEnabled,
    setNextStep,
}: Props) => {
    const currentSend = campaignState.sends[activeSendIndex];
    const { callerProfileId } = currentSend as VoiceCallSendInput;
    // TODO : const { contentId, callerProfileId } = currentSend as VoiceCallSendInput;

    useEffect(() => {
        // Voice Call curations don't support delayed sends yet, but we're leaving this here for when it does
        if (activeSendIndex > 0) {
            setNextStep(CustomCampaignSteps.CHOOSE_NEXT_STEP);
        } else {
            setNextStep(CustomCampaignSteps.SCHEDULE_CURATION);
        }
        // The dependency array is empty because this useEffect should run exactly once after the initial rendering of the component.
        // The hook is not dependent on any props or state variables to re-run. This is intended to perform the initial setup based on
        // the `activeSendIndex` value when the component mounts without reacting to any further updates or re-renders.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // TODO : const isEnabled = !!contentId && !!callerProfileId;
        const isEnabled = !!callerProfileId;
        setIsContinueEnabled(isEnabled);
    }, [callerProfileId, setIsContinueEnabled]);

    const callerProfiles: VoiceCallerProfile[] = [
        { id: 1, name: 'FirstName LastName' },
        { id: 2, name: 'Testing Name' },
    ]; //TODO fetch data when api is ready

    const callerProfilesValues =
        callerProfiles?.map((profile: VoiceCallerProfile) => ({
            value: profile.id,
            label: `${profile.name}<+1(216)902-8888>`, //TODO update dynamically to {profile.phoneNumber} when api is ready
        })) || [];

    const onCallerProfileChange = (e: any) =>
        dispatchCampaignAction(setCallerProfileId(e.target.value, activeSendIndex));

    const StepIcon = channelIcons.VOICECALL;

    return (
        <FormLayout header="select content" icon={<StepIcon />} guidingText={GUIDING_TEXT} stepName="voice call">
            <Stack rowGap={4}>
                <AutocompleteVoiceCallContent />
                <Select
                    fullWidth
                    label={<InputLabel label="Caller ID" labelIconTooltipText="Who is making the voice call?" />}
                    menuItemStyles={{ textTransform: 'none' }}
                    onChange={onCallerProfileChange}
                    required
                    value={callerProfileId ?? ''}
                    values={callerProfilesValues}
                />
            </Stack>
        </FormLayout>
    );
};

export default VoiceCallContentStep;
