import { AccessTime } from '@mui/icons-material';
import { FormHelperText, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import Select from 'domains/core/components/Select';
import TextField from 'domains/core/components/TextField';
import FormLayout from 'domains/core/components/FormLayout';
import { CustomCampaignSteps } from 'domains/campaigns/components/CustomCampaign/CustomCampaign';
import { setParentAudienceDelayInterval } from 'domains/campaigns/components/CustomCampaign/customCampaignActions';
import { CampaignStateTypes } from 'domains/campaigns/components/CustomCampaign/customCampaignReducer';
import { getDelayUnitAndValue } from 'domains/campaigns/components/CustomCampaign/utils';
import { CampaignChannels } from 'domains/campaigns/types';
import { RelativeTimeframeUnit } from 'models/types';
import DelayInfo from './DelayInfo';

const getErrorMessage = (delayValue?: string, selectedDelayUnit?: string): string => {
    const delayValueAsNum = delayValue && Number(delayValue);

    if (delayValueAsNum === 0) {
        return 'The delay must be greater than 0.';
    }

    if (!delayValue || !selectedDelayUnit) return '';

    const dateIsValid =
        (selectedDelayUnit === RelativeTimeframeUnit.DAY && delayValueAsNum < 365) ||
        (selectedDelayUnit === RelativeTimeframeUnit.HOUR && delayValueAsNum < 8760);

    if (dateIsValid) return '';

    return 'Time delays cannot be more than one year into the future.';
};

const timeframeValues = [
    { value: RelativeTimeframeUnit.HOUR, label: 'Hours' },
    { value: RelativeTimeframeUnit.DAY, label: 'Days' },
];

type Props = {
    activeSendIndex: number;
    campaignState: CampaignStateTypes;
    dispatchCampaignAction: React.Dispatch<any>;
    setIsContinueEnabled: (isContinueEnabled: boolean) => void;
    setNextStep: (step: CustomCampaignSteps) => void;
};

const CustomCampaignTimeDelayStep = ({
    activeSendIndex,
    campaignState,
    dispatchCampaignAction,
    setIsContinueEnabled,
    setNextStep,
}: Props) => {
    const [delayValue, setDelayValue] = useState('');
    const [selectedDelayUnit, setSelectedDelayUnit] = useState('');

    const activeSend = campaignState.sends[activeSendIndex];
    const isActiveSendEmail = activeSend?.channel === CampaignChannels.EMAIL;
    const errorMessage = getErrorMessage(delayValue, selectedDelayUnit);

    useEffect(() => {
        const delay = isActiveSendEmail && activeSend?.parentAudienceDelayInterval;
        const { initialDelayValue, initialDelayUnit } = getDelayUnitAndValue(delay);
        setDelayValue(initialDelayValue);
        setSelectedDelayUnit(initialDelayUnit);

        setNextStep(CustomCampaignSteps.CHOOSE_SEND_TYPE);
        // The dependency array only contains `activeSendIndex` because we only want it to run
        // if the component rerenders when we're switching from one time delay to another in the nav.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSendIndex]);

    useEffect(() => {
        setIsContinueEnabled(!!delayValue && !!selectedDelayUnit && !errorMessage);
    }, [delayValue, selectedDelayUnit, errorMessage, setIsContinueEnabled]);

    useEffect(() => {
        if (!!delayValue && !!selectedDelayUnit) {
            const delayInterval = `${delayValue} ${selectedDelayUnit}`;
            dispatchCampaignAction(setParentAudienceDelayInterval(delayInterval, activeSendIndex));
        }
        // We only want this to run if the send changes or the delay values are updated.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSendIndex, delayValue, selectedDelayUnit]);

    const handleDelayChange = (e: any) => {
        // Don't allow decimal or negative values.
        if (e.target.value.includes('.') || e.target.value.includes('-')) return;

        const delayAsNum = Number(e.target.value);
        // Very large numbers cause Dates to be invalid, so limit the delay.
        if (delayAsNum < 10000) {
            setDelayValue(e.target.value);
        }
    };

    const handleUnitChange = (e: any) => setSelectedDelayUnit(e.target.value);

    const shouldShowDelayInfo = !!delayValue && !!selectedDelayUnit && !errorMessage;

    return (
        <FormLayout
            guidingText="Choose how long you want to wait before sending your next email. This time should align with your Curation's objective."
            header="set up time delay"
            stepName="time delay"
            icon={<AccessTime />}
        >
            <>
                <Stack spacing={4} width="100%">
                    <Stack spacing={2} direction="row">
                        <TextField
                            label="Delay"
                            type="number"
                            value={delayValue}
                            onChange={handleDelayChange}
                            inputProps={{ maxLength: 255 }}
                            fullWidth
                        />
                        <Select
                            label="Timeframe"
                            values={timeframeValues}
                            onChange={handleUnitChange}
                            value={selectedDelayUnit}
                        />
                    </Stack>
                    {errorMessage && (
                        <FormHelperText error sx={{ alignSelf: 'flex-start', marginTop: '8px !important' }}>
                            {errorMessage}
                        </FormHelperText>
                    )}
                    {shouldShowDelayInfo && <DelayInfo delayUnit={selectedDelayUnit} delayValue={delayValue} />}
                </Stack>
            </>
        </FormLayout>
    );
};

export default CustomCampaignTimeDelayStep;
