import { Typography } from '@mui/material';
import theme from 'theme';
import Alert from 'domains/core/components/Alert';

type Props = {
    delayUnit: string;
    delayValue: string;
};

const sharedTypographyStyles = {
    component: 'span',
    fontSize: '16px',
};

const DelayInfo = ({ delayUnit, delayValue }: Props) => {
    const renderScheduledInfo = () => {
        const delayValueAsNum = Number(delayValue);
        const delayUnitText = ` ${delayValue} ${delayUnit}${delayValueAsNum !== 1 ? 's' : ''} after previous send`;

        return delayUnitText;
    };

    return (
        <Alert
            elevation={0}
            severity="info"
            variant="outlined"
            style={{ borderColor: theme.palette.info.main, borderWidth: '2px' }}
        >
            Email is scheduled to send
            <Typography color="success.dark" {...sharedTypographyStyles}>
                {renderScheduledInfo()}
            </Typography>
        </Alert>
    );
};

export default DelayInfo;
